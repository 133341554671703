@import url("https://fonts.googleapis.com/css?family=Merriweather:700,900&display=swap");
@font-face {
  font-family: sk-modernist;
  src: url("/assets/fonts/sk-modernist-regular-webfont.woff2");
}
@font-face {
  font-family: sk-modernist;
  src: url("/assets/fonts/sk-modernist-bold-webfont.woff2");
  font-weight: bold;
}
@import "vars";
@import "../node_modules/bootstrap/scss/bootstrap";

.btn {
  &:focus,
  &:active,
  &:active:focus,
  &.active:focus {
    outline: none;
  }

  &-secondary {
    background-color: $background-gray;
    border-color: #ccc;
    color: $primary;

    &:hover {
      background-color: $gray-300;
      border-color: $gray-500;
      color: $gray-900;
    }

    &.disabled {
      background-color: $white;
      border-color: lighten(#ccc, 5%);
      color: lighten($gray-900, 5%);
    }
  }

  &-warning {
    color: $white;
  }

  &-primary:focus {
    box-shadow: 0 0 5px lighten($primary, 10%);
  }

  &-secondary:focus {
    box-shadow: 0 0 5px $gray-400;
  }

  &-success:focus {
    box-shadow: 0 0 5px lighten($success, 10%);
  }

  &-info:focus {
    box-shadow: 0 0 5px lighten($info, 10%);
  }

  &-warning:focus {
    box-shadow: 0 0 5px lighten($warning, 10%);
  }

  &-danger:focus {
    box-shadow: 0 0 5px lighten($danger, 10%);
  }

  &.disabled:focus {
    box-shadow: none;
  }

  box-shadow: $btn-box-shadow;
}

// .portlet-login .form fieldset {
//   border-width: 0;
//   border-top-width: 0px;
//   border-right-width: 0px;
//   border-bottom-width: 0px;
//   border-left-width: 0px;
//   margin-bottom: 0;
//   padding: 0;
// }

// .text-primary {
//   color: #337ab7 !important;
// }

// button,
// input,
// optgroup,
// select,
// textarea {
//   font-family: inherit !important;
//   font-weight: inherit !important;
// }

// .button-blue-brand.secondary {
//   background: #eeeef0 !important;
// }

// input[type="email"]:focus,
// form input[type="email"]:hover,
// form input[type="text"]:focus,
// form input[type="text"]:hover,
// form input[type="password"]:focus,
// form input[type="password"]:hover,
// form input[type="submit"]:focus,
// form input[type="submit"]:hover,
// form textarea:focus,
// form textarea:hover {
//   border-color: #6891ae !important;
//   border-top-color: rgb(104, 145, 174) !important;
//   border-right-color: rgb(104, 145, 174) !important;
//   border-bottom-color: rgb(104, 145, 174) !important;
//   border-left-color: rgb(104, 145, 174) !important;
//   box-shadow: none !important;
// }
